import React from 'react';
import { Router, Route, Switch } from 'dva/router';
import BasicLayout from '../pages/auth/BasicLayout';
import AppLogin from '../pages/auth/login'; 
import AppRegister from '../pages/auth/register';
import AppVarify from '../pages/auth/varify';
import Home from "../pages/Home/Home";
import Static from '../pages/Static/Static';
import SupportCenter from '../pages/Static/Support';

function hasLogin() {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    try {
        const objUser = JSON.parse(user);
        return token && objUser ? true : false;
    } catch (e) {
        console.error("Error parsing user data from localStorage:", e);
        return false;
    }
}

const PrivateRoute = ({ component: Component, fallback: FallbackComponent, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      hasLogin() ? (
        <Component {...props} />
      ) : (
        <FallbackComponent {...props} />
      )
    }
  />
);

function RouterConfig({ history }) {
  return (
    <Router history={history} basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path='/login' component={AppLogin} />
        <Route exact path='/static-pages/:slug' component={Static} />
        <Route exact path='/support-request' component={SupportCenter} />
        <Route exact path='/verify' component={AppVarify} />
        <Route exact path='/register' component={AppRegister} />
        <PrivateRoute path='/' component={BasicLayout} fallback={Home} />

      </Switch>
    </Router>
  );
}

export default RouterConfig;
